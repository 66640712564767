<template>
  <div class="image-and-text">
    <div class="title-btn">
      <gaudy-title label="课程学习">
        <div class="back-btn" @click="handlerBack">
          <svg-icon icon="icon-fanhui" />
          <span>返回上一页</span>
        </div>
      </gaudy-title>
    </div>
    <div class="image-and-text-body" ref="imageTextBody">
      <div class="text-header">
        <div class="header-title">
          {{ ImageAndTextInfo.courseContName }}
        </div>
        <div class="header-second-level">
          <span>来源：{{ ImageAndTextInfo.source }}</span>
          <span>发布时间：{{ ImageAndTextInfo.releaseTime }}</span>
        </div>
      </div>
      <div class="header-tips">
        阅读文章全部内容并通过随堂测试后即可学习完成，本页总字数：{{
          ImageAndTextInfo.wordCount
        }}字，预计阅读时长：{{ ImageAndTextInfo.totalSeconds }}秒
      </div>
      <div class="image-and-text-content">
        <div class="details" v-html="ImageAndTextInfo.courseCont"></div>
      </div>
    </div>
    <div class="action-btn" ref="actionBtn" :style="{ right: actionBtnRight }">
      <div class="action-btn-item state" v-if="ImageAndTextInfo.isWanCheng">
        <div class="icon">
          <svg-icon
            v-if="ImageAndTextInfo.isTongGuo"
            icon="icon-wancheng"
            color="#5EB35E"
          />
          <i v-else style="color: #e6a23c" class="el-icon-warning" />
        </div>
        <div class="label">
          {{ ImageAndTextInfo.isTongGuo ? "完成" : "待考试" }}
        </div>
      </div>
      <div class="action-btn-item state" v-else>
        <div class="icon">
          <el-progress
            v-if="!isNaN(percentage)"
            :width="40"
            color="#d71917"
            type="circle"
            :stroke-width="6"
            :percentage="percentage"
            status="exception"
          ></el-progress>
        </div>
        <div class="label">{{ percentage.toFixed(0) }}%</div>
      </div>
      <div class="action-btn-item" v-if="scrollTop > 0" @click="goTopBtn()">
        <div class="icon">
          <svg-icon icon="icon-rongqi" />
        </div>
        <div class="label">顶部</div>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/svgIcon.vue";
import GaudyTitle from "@/components/gaudyTitle.vue";
import { getGongXuUserCourseById } from "@/api/publicDemandController";
import {
  getStudyProcessEnd,
  getStudyProcessStart,
  getStudyProcessUpdate,
} from "@/api/specializedController";
import { showModel } from "@/utils/tools";
import * as storage from "@/utils/storage";
export default {
  components: { GaudyTitle, SvgIcon },
  data() {
    return {
      courseContId: 0,
      scrollTop: 0,
      actionBtnRight: 0,
      ImageAndTextInfo: {},
      percentage: 0,
      precossTime: 0,
      countdown: null,
      editPlayTimer: null,
      studyProcessId: 0,
    };
  },
  computed: {
    yearId() {
      return storage.getStore("yearId");
    },
  },
  created() {
    this.courseContId = this.$route.query.courseContId;
    window.addEventListener("scroll", this.onScroll, false);
    this.getPublicInfo();
  },
  mounted() {
    this.actionBtnRight = `calc(50% - ${
      this.$refs.imageTextBody.offsetWidth / 2 +
      this.$refs.actionBtn.offsetWidth +
      16
    }px)`;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, false);
    clearInterval(this.countdown);
    this.countdown = null;
    clearInterval(this.editPlayTimer);
    this.editPlayTimer = null;
  },
  methods: {
    goTopBtn() {
      const timer = setInterval(() => {
        // 文档滚动的距离
        const top = document.documentElement.scrollTop;
        // 设置步长，便于实现动画滚动
        const speed = Math.ceil(top / 10);
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= speed;
        } else {
          // 当返回顶部时，清除定时器
          clearInterval(timer);
        }
      }, 5);
    },
    onScroll() {
      this.scrollTop = document.documentElement.scrollTop;
    },
    // 获取公需课详情
    getPublicInfo() {
      getGongXuUserCourseById({
        courseContId: this.courseContId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        this.ImageAndTextInfo = res.data;
        if (res.data.isWanCheng && !res.data.isTongGuo) {
          showModel({
            content: "恭喜您已经完成所有课程学习，请前往考试吧",
            showClose: true,
            showCancelButton: true,
            confirmButtonText: "前往考试",
            closeModal: false,
            confirm: () => {
              console.log("前往考试");
              this.keepAliveNavigation(
                "examination?courseId=" + res.data.studyCourseId
              );
            },
          });
        }
        if (!(res.data.isWanCheng || res.data.isTongGuo)) {
          this.countdownStart();
        }
      });
    },
    // 开始学习
    countdownStart() {
      const that = this;
      getStudyProcessStart({
        ppXmWjbzp5b8: this.ImageAndTextInfo.courseContId,
        c3R1ZHlDb3Vyc2VJZA: this.ImageAndTextInfo.studyCourseId,
        trainingId: this.yearId,
      }).then((res) => {
        if (res.data.playingTime) {
          that.precossTime = res.data.playingTime;
        }
        this.studyProcessId = res.data.studyProcessId;
        // 学习时间记录
        this.countdown = setInterval(() => {
          if (that.precossTime < that.ImageAndTextInfo.totalSeconds) {
            that.percentage = Number(
              (that.precossTime /
                parseFloat(that.ImageAndTextInfo.totalSeconds)) *
                100
            );
            that.precossTime += 0.1;
          } else {
            that.precossTime = Number(that.ImageAndTextInfo.totalSeconds);
            clearInterval(this.countdown);
            that.countdown = null;
            clearInterval(this.editPlayTimer);
            that.editPlayTimer = null;
            // 学习结束请求
            getStudyProcessEnd({
              c3R1ZHlQcm9jZXNzSWQ: that.studyProcessId,
              trainingId: this.yearId,
            }).then((res) => {
              that.ImageAndTextInfo.isWanCheng = true;
              that.percentage = 100;
              if (res.data.state === 2) {
                showModel({
                  content: "恭喜您已经完成所有课程学习，请前往考试吧",
                  showClose: true,
                  showCancelButton: true,
                  confirmButtonText: "前往考试",
                  closeModal: false,
                  confirm: () => {
                    console.log("前往考试");
                    this.keepAliveNavigation(
                      "examination?courseId=" + res.data.courseId
                    );
                  },
                });
              }
            });
          }
        }, 100);
        // 每间隔2分钟上传一次时间
        if (!this.editPlayTimer) {
          this.editPlayTimer = setInterval(() => {
            that.setStudyProcessEdit();
          }, 120000);
        }
      });
    },
    // 记录时间请求
    setStudyProcessEdit() {
      getStudyProcessUpdate({
        c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
        cgxhewluz1rpbwu: Math.ceil(this.precossTime),
        trainingId: this.yearId,
      }).then(() => {});
    },
  },
};
</script>
<style scoped lang="scss">
.image-and-text {
  position: relative;
  padding: 16px 0;
  .title-btn {
    width: calc(100% - 150px);
    margin: 0 auto 16px;
    .back-btn {
      border-radius: 52px;
      padding: 6px 16px;
      background: #b50413;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      span {
        margin-left: 6px;
      }
    }
  }
  .image-and-text-body {
    width: calc(100% - 150px);
    min-height: calc(100vh - 330.46px);
    margin: 0 auto;
    box-sizing: border-box;
    padding: 18px 24px;
    background: #ffffff;
    .text-header {
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 1px solid #ebebeb;
      .header-title {
        color: #21252e;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .header-second-level {
        color: #818496;
        span {
          margin: 0 20px;
        }
      }
    }
    .header-tips {
      padding: 15px 0;
      color: #d71917;
      font-size: 14px;
      border-bottom: 1px solid #ebebeb;
      text-align: center;
    }
    .image-and-text-content {
      width: 100%;
      .details {
        width: 100%;
        overflow-x: hidden;
        ::v-deep section {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
  }
  .action-btn {
    position: fixed;
    bottom: 142px;
    .action-btn-item {
      width: 60px;
      background-color: #ffffff;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 10px 4px;
      margin-top: 15px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .icon {
        font-size: 25px;
        line-height: 1;
        color: #575b66;
      }
      .label {
        margin-top: -5px;
        font-size: 12px;
        color: #3d3d3d;
      }
      &:hover {
        background-color: #d71917;
        color: #ffffff;
        .icon {
          color: #ffffff;
        }
        .label {
          color: #ffffff;
        }
      }
      &.state {
        pointer-events: none;
        .icon {
          ::v-deep .el-progress .el-progress__text {
            display: none;
          }
        }
        .label {
          margin-top: 2px;
          color: #d71917;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
